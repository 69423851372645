import React from "react";
import { TextArea, Button, Input } from "components/common";
import { Center, InputField, StyledForm } from "./styles";

const ContactForm = () => (
	<StyledForm action={process.env.GATSBY_FORMSPREE_ENDPOINT} method="POST">
		<InputField>
			<Input type="text" name="full-name" placeholder="Name" required />
		</InputField>
		<InputField>
			<Input type="text" name="_subject" placeholder="subject" required />
		</InputField>
		<InputField>
			<Input type="email" name="_replyto" placeholder="email" required />
		</InputField>
		<InputField>
			<TextArea name="message" placeholder="Enter your message" required />
		</InputField>
		<input type="text" name="_gotcha" style={{ display: 'none' }} />
		<Center>
			<Button secondary type="submit">
				Submit
			</Button>
		</Center>
	</StyledForm>
)

export default ContactForm;
